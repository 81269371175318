import React from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import notfound from "../images/404.png";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <br />
      <img src={notfound} alt="404" width="300" />
      <br />
      <p>
        <a href="/">Get back...</a>
      </p>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
